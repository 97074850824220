<template>
  <div class="card mb-5 mb-xl-12">
    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
      
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Slaytlar</h3>

        
      </div>      
    </div>
    
    
    <div id="kt_account_profile_details" class="collapse show" v-if="items">

        <div id="package-detail" v-if="showDetail">
            <div class="card-body border-top p-9">                
                <EditForm :selectedItem="selectedItem" :mode="mode" v-if="selectedItem" />
            </div>

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button type="reset" @click.prevent="cancelForm()" class="btn btn-white btn-active-light-primary me-2">Vazgeç</button>

                <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" @click.prevent="update()" class="btn btn-primary">
                    <span class="indicator-label"> Kaydet </span>
                    <span class="indicator-progress">
                    Lütfen bekleyin...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
            <!--end::Actions-->
        </div>


        <div class="card-body border-top p-9" v-show="!showDetail">
            <div class="table-responsive">
                <a href="" @click.prevent="add()" class="btn btn-primary float-right">Yeni Slide Ekle</a>
              <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Görsel</th>
                    <th>Ad</th>
                    <th>Slide Tipi</th>
                    <th>Durum</th>                    
                    <th>İşlem</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in items" :key="i">
                    <td><img @click.prevent="showImageUpload(item)" width="100" :src="'https://api.ytmdanismanlik.com'+item.directory+item.imagePhone"></td>
                    <td>{{item.sliderName}}</td>
                    <td>{{item.sliderTypeId}}</td>
                    <td><span :class="'badge badge-light-'+statusses.class[item.status]">{{statusses.label[item.status]}}</span></td>                    
                    <td><ActionsMenu :item="item" :packagekey="i" @edit="edit($event)" @remove="remove($event)" /></td>
                    </tr>            
                </tbody>
              </table>
      
            </div>
        </div>
    </div>
    
        
    
  </div>
<div style="display:none">
  <div id="slider-image" v-show="pickedItem" ref="sliderPhotoItem">
      <div id="slider-image-wrapper">
          <div id="slider-mobile-image" class="slider-image-cell">
              <img :src="'https://api.ytmdanismanlik.com'+pickedItem.directory+pickedItem.imagePhone" alt="" v-if="pickedItem">
              
              <div v-show="uploadStarted">
                  Dosya yükleniyor, lütfen bekleyin...
              </div>
              <div class="file-upload-wrapper" v-show="!uploadStarted">
                <input type="file" name="file" ref="phoneFile" id="">
                <button @click.prevent="uploadImage(1)" class="btn btn-success">Yükle</button>
              </div>

          </div>
          <div id="slider-tablet-image" class="slider-image-cell">
              <img :src="'https://api.ytmdanismanlik.com'+pickedItem.directory+pickedItem.imageTablet" alt="" v-if="pickedItem">

              <div v-show="uploadStarted">
                  Dosya yükleniyor, lütfen bekleyin...
              </div>
              <div class="file-upload-wrapper" v-show="!uploadStarted">
                <input type="file" name="file" id="">
                <button @click.prevent="uploadImage(2)" class="btn btn-success">Yükle</button>
              </div>

              <div v-show="phoneFileUploaded">
                    Görsel güncellendi.  
                </div>   

          </div>
      </div>
  </div>
</div>

</template>



<script>
import api from '@/core/services/ApiService'
import ActionsMenu from "@/components/dropdown/SlideListActions.vue";
import EditForm from '@/components/modals/forms/EditMobileSlider.vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";

const newItemValues={
    buttonLink:null,
    orderNo:null,
    sliderName:null,
    sliderTypeId:null,
    status:'AC',
    statusButton:true,
    statusText:true,
    statusTitle:true,
    text:null,
    title:null
}

export default  {
  name:'PackageGroups',
  components:{
      ActionsMenu,
      EditForm,      
  },
  data(){
    
    return{        
        selectedItem:newItemValues,
        mode:'new',
        pickedItem:null,
        uploadStarted:false,
        phoneFileUploaded:false,
        tabletFileUploaded:false,

      showDetail:false,
      
      statusses : {
        class:{
          'AC':'success',
          'DA':'danger'
        },
        label:{
          'AC':'Aktif',
          'DA':'Pasif'
        }
      },       
      items:null,
      

    }
  },

  

  methods:{
      showImageUpload(item){
          this.pickedItem = item;
          let html = this.$refs['sliderPhotoItem'];
          Swal.fire({
              title:'Slide Görseli',
              text:'Slide için görsel seçin',
              html:html,
              width: 900,
          });
      },

      uploadImage(type){
          this.uploadStarted=true;
          let uploadUrl = '/Mobile/UploadImage/Slider/'+type+'/'+this.pickedItem.sliderId;
          let formData = new FormData();
          let file = type == 1 ? this.$refs['phoneFile'].files[0] : this.$refs['tabletFile'].files[0];
          formData.append('Files',file);
          api.post(uploadUrl,formData).then((res)=>{
              this.uploadStarted=false;
              if(type==1){this.phoneFileUploaded=true}
              if(type==2){this.tabletFileUploaded=true}

              this.pickedItem.imagePhone = res.data.image;

          }).catch((err)=>{
              this.uploadStarted=false;
              console.log(err);
          })
        
      },

      edit(item){
          this.showDetail=true;
          this.mode='edit';
          this.selectedItem = item;
      },

      cancelForm(){
          this.showDetail=false;
      },

      add(){
          this.mode='new';
          this.selectedItem = newItemValues;        
          this.showDetail = true;

      },


      remove(item){
        console.log(item);
        Swal.fire({
          icon:'warning',
          title:'Slide\'ı silmek üzeresiniz',
          text:item.sliderName +' slide\'ını silmek istediğinize emin misiniz?\nBu işlemin geri dönüşü yoktur!',
          showCancelButton:true
        }).then((result)=>{
          if(result.isConfirmed){
            api.get('/Mobile/DeleteSlider/'+item.sliderId).then((res)=>{
              Swal.fire({
                title:'Slide Silme',
                icon:'success',
                text:'Slide silindi',
              }).then(()=>{
                window.location.reload();
              })
            });
          }        
        });
      },


      

      update(){
          let apiAddress = this.mode=='new' ? '/Mobile/NewSlider' : '/Mobile/UpdateSlider';
            this.selectedItem.sliderTypeId = parseInt(this.selectedItem.sliderTypeId);
          api.post(apiAddress,this.selectedItem).then((res)=>{
              Swal.fire({
                  text:'Mobil Slayt güncellendi',
                  icon:'success',
              }).then(()=>{
                  window.location.reload();
              });
          }).catch((err)=>{
              console.log(err);
          })
      }
  },


  created(){
     setCurrentPageBreadcrumbs("Slaytlar", ["Ayarlar"]);
     api.get('/Mobile/ListSliders/JJ').then((res)=>{
         this.items = res.data;
     });
   
  },

}

</script>
<style>
input:disabled{cursor: not-allowed; background-color: #eee;}
#slider-image-wrapper{width:100%; max-width: 800px; display: flex; }
#slider-image-wrapper .slider-image-cell{width:calc(50% - 10px); margin:0 5px; box-sizing: border-box; padding: 10px; border:1px solid #ccc; display: flex; flex-direction: column;}
#slider-image-wrapper .slider-image-cell img{width:100%; display: block;}
.file-upload-wrapper{display: flex; align-items: center; justify-content: center;  padding: 30px 0;}

.swal2-container .swal2-html-container{max-height: 500px !important;}
</style>