<template>
    <div>
        
        <div class="row">
            <div class="col-lg-9">
                <div class="row mb-10">
                    <div class="col-lg-6 fv-row">
                        <label class="col-form-label">Ad</label>                            
                        <input type="text" name="sliderName" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="sItem.sliderName" />
                    </div>
                    <div class="col-lg-6 fv-row">
                        <label class="col-form-label">Başlık</label>                            
                        <input type="text" name="title" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="sItem.title" />
                    </div>
                </div>

                <div class="row mb-10">
                    <div class="col-lg-12 fv-row">
                        <label class="col-form-label">İçerik</label>                            
                        <textarea name="text" class="form-control form-control-solid" rows="5" v-model="sItem.text"></textarea>
                    </div>
                </div>

                <div class="row mb-10">
                    <div class="col-lg-6 fv-row">
                        <label class="col-form-label">Slide Tipi</label>
                        <select name="sliderTypeId" class="form-select form-select-solid" v-model="sItem.sliderTypeId">
                            <option value="">Seçiniz</option>
                            <option :value="i" v-for="item,i in slideTypes" :key="i">{{item}}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 fv-row">
                        <label class="col-form-label">Sıra</label>
                        <input type="number" class="form-control form-control-solid" name="orderNo" id="orderNo" v-model="sItem.orderNo">
                    </div>
                </div>

                <div class="row mb-10">
                    <div class="col-lg-6 fv-row">
                        <label class="col-form-label">Link URL</label>                            
                        <input type="text" name="buttonLink" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="sItem.buttonLink" />
                    </div>

                    <div class="col-lg-6 fv-row">
                        ytm://packages > Paketler Sayfası<br>
                        ytm://points > Bonus Paketler Sayfası<br>
                        ytm://appointments > Randevulu Danışmanlar Sayfası<br>
                        ytm://planned > Planlı Takip Danışmanlar Sayfası<br>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 fv-row">
                        <label class="col-form-label">Durum</label> 
                            <div class="d-flex align-items-center mt-3">
                            <label class="form-check form-check-inline form-check-solid me-5">
                                <input class="form-check-input" value="AC" name="status" :checked="sItem.status == 'AC'" type="radio" v-model="sItem.status" />
                                <span class="fw-bold ps-2 fs-6"> Aktif </span>
                            </label>

                            <label class="form-check form-check-inline form-check-solid">
                                <input class="form-check-input"  value="DA" name="status" :checked="sItem.status == 'DA'" type="radio" v-model="sItem.status" />
                                <span class="fw-bold ps-2 fs-6"> Pasif </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import labels from '@/core/config/labels'
export default {
    name:'EditMobileSlider',
    props:['selectedItem','mode'],

    data(){
        return {
            slideTypes:labels.sliderTypes,
            sItem:this.selectedItem
        }
    }
   
        
}
</script>